import Colors from './Colors'
import Layout from './Layout'

const paddingSmall = 8
const paddingMedium = 16
const paddingLarge = 32
const paddingXL = 64

const borderRadiusSmall = 3
const borderRadiusMedium = 6
const borderRadiusLarge = 9
const borderRadiusXL = 12
const borderRadiusMax = 100

export default {
    paddingSmall,
    paddingMedium,
    paddingLarge,
    paddingXL,

    borderRadiusSmall,
    borderRadiusMedium,
    borderRadiusLarge,
    borderRadiusXL,
    borderRadiusMax,

    // Default button styles
    button: {
        alignItems: 'center',
        justifyContent: 'center',
        //borderRadius: 3,
        paddingVertical: 12,
        paddingHorizontal: 18,
        backgroundColor: Colors.tintColor,
        borderWidth: 1,
        borderColor: Colors.tintColor,
    },

    // button: {
    //     //alignSelf: 'center',
    //     alignItems: 'center',
    //     justifyContent: 'center',
    //     borderRadius: Layout.borderRadiusLarge, // was 3
    //     paddingVertical: 12,
    //     paddingHorizontal: 18,
    // },

    buttonInverse: {
        alignItems: 'center',
        justifyContent: 'center',
        //borderRadius: 3,
        paddingVertical: 12,
        paddingHorizontal: 18,
        backgroundColor: Colors.white,
        borderWidth: 1,
        borderColor: Colors.white,
    },

    buttonOutline: {
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 12,
        paddingHorizontal: 18,
        backgroundColor: 'transparent',
        borderWidth: 1,
        borderColor: Colors.tintColor,
    },

    buttonOutlineInverse: {
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 12,
        paddingHorizontal: 18,
        backgroundColor: 'transparent',
        borderWidth: 1,
        borderColor: Colors.white,
    },

    buttonUnderline: {
        borderWidth: 0,
        backgroundColor: Colors.transparentBackground,
        paddingVertical: 10,
        paddingHorizontal: 12,
    },

    buttonMini: {
        alignSelf: 'flex-start',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 3,
        paddingVertical: 10,
        paddingHorizontal: 12,
        backgroundColor: 'transparent',
        borderWidth: 1,
        borderColor: Colors.tintColor,
    },

    buttonMiniInverse: {
        alignSelf: 'flex-start',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 3,
        paddingVertical: 10,
        paddingHorizontal: 12,
        backgroundColor: 'transparent',
        borderWidth: 1,
        borderColor: Colors.white,
    },

    buttonMiniDisabled: {
        alignSelf: 'flex-start',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 3,
        paddingVertical: 10,
        paddingHorizontal: 12,
        backgroundColor: 'transparent',
        borderWidth: 1,
        borderColor: Colors.disabled,
    },

    buttonOptionSelected: {
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 3,
        paddingVertical: 8,
        paddingHorizontal: 10,
        backgroundColor: Colors.tintColor,
        borderWidth: 1,
        borderColor: Colors.tintColor,
        marginRight: Layout.isMediumDevice ? 0 : 12,
        marginHorizontal: Layout.isMediumDevice ? 6 : 0,
    },

    buttonOption: {
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 3,
        paddingVertical: 8,
        paddingHorizontal: 10,
        backgroundColor: 'transparent',
        borderWidth: 1,
        borderColor: Colors.tintColor,
        marginRight: Layout.isMediumDevice ? 0 : 12,
        marginHorizontal: Layout.isMediumDevice ? 6 : 0,
    },

    disabledButton: {
        alignItems: 'center',
        justifyContent: 'center',
        //borderRadius: 3,
        paddingVertical: 12,
        paddingHorizontal: 18,
        backgroundColor: 'transparent',
        borderWidth: 1,
        borderColor: Colors.disabled,
    },

    buttonIcon: {
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 3,
        paddingVertical: 16,
        paddingHorizontal: 16,
        backgroundColor: Colors.tintColor,
        borderWidth: 1,
        borderColor: Colors.tintColor,
    },

    disabledButtonIcon: {
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 3,
        paddingVertical: 16,
        paddingHorizontal: 16,
        backgroundColor: 'transparent',
        borderWidth: 1,
        borderColor: Colors.disabled,
    },

    menuBackButton: {
        backgroundColor: 'transparent',
        alignSelf: 'flex-start',
        backgroundColor: Colors.white,
        borderWidth: 0,
        shadowColor: Colors.shadowColor,
        shadowOffset: Colors.shadowOffsetDepth,
        shadowOpacity: Colors.shadowOpacityDepth,
        shadowRadius: Colors.shadowRadiusDepth,
        elevation: Colors.elevationDepth,
    },

    menuBackButtonIcon: {
        borderRadius: 100,
        backgroundColor: Colors.white,
        borderWidth: 0,
        shadowColor: Colors.shadowColor,
        shadowOffset: Colors.shadowOffsetDepth,
        shadowOpacity: Colors.shadowOpacityDepth,
        shadowRadius: Colors.shadowRadiusDepth,
        elevation: Colors.elevationDepth,
    },

    menuButton: {
        borderRadius: 100,
        backgroundColor: Colors.tintColor,
        borderColor: Colors.tintColor,
        borderWidth: 0,
        shadowColor: Colors.shadowColor,
        shadowOffset: Colors.shadowOffsetDepth,
        shadowOpacity: Colors.shadowOpacityDepth,
        shadowRadius: Colors.shadowRadiusDepth,
    },

    menuButtonTransparentBG: {
        borderRadius: 100,
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        borderWidth: 0,
    },

    drawerStickyButtonContainer: {
        zIndex: 100,
        position: 'absolute',
        top: Layout.paddingSmall,
        left: Layout.paddingSmall,
    },

    drawerStickyButton: {
        borderRadius: 100,
        backgroundColor: Colors.white,
        borderWidth: 0,
        shadowColor: Colors.shadowColor,
        shadowOffset: Colors.shadowOffsetDepth,
        shadowOpacity: Colors.shadowOpacityDepth,
        shadowRadius: Colors.shadowRadiusDepth,
        elevation: Colors.elevationDepth,
    },

    carouselButton: {
        width: Layout.isMediumDevice ? '50%' : 80,
        height: Layout.isMediumDevice ? 56 : 80,
        borderRadius: 0,
        borderWidth: 0,
        backgroundColor: Colors.darkBackground,
    },

    carouselButtonContainer: {
        paddingTop: 1,
        flexDirection: 'row',
        zIndex: 100,
        position: Layout.isMediumDevice ? 'relative' : 'absolute',
        top: Layout.isMediumDevice ? null : 0,
        bottom: Layout.isMediumDevice ? null : 0,
        width: Layout.SCREEN_WIDTH,
        justifyContent: 'space-between',
        alignItems: 'center',
    },
}
