import React from 'react'
import { TouchableOpacity, StyleSheet, Image, Text, View } from 'react-native'
import { Colors, Buttons, Layout, TextStyles } from '../constants'
import { UserProfileConsumer } from '../context/UserProfileContext'
import Button from './Button'

export default class Footer extends React.Component {
    render() {
        return (
            <UserProfileConsumer>
                {(context) => {
                    return (
                        <View
                            style={{
                                width: '100%',
                                alignSelf: 'center',
                                backgroundColor: Colors.tintColor,
                            }}
                        >
                            <View style={styles.footer}>
                                {context.userExists &&
                                !Layout.isMediumDevice ? (
                                    <View
                                        style={{
                                            flex: 1,
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                        }}
                                    >
                                        {/* <Button
                                            iconRight={true}
                                            iconName={'log-out'}
                                            iconColor={Colors.tintColor}
                                            navigation={this.props.navigation}
                                            onPress={context.userSignOut}
                                            buttonStyle={Buttons.buttonMini}
                                            textColor={Colors.tintColor}
                                            textStyle={TextStyles.Button}
                                            title={'Log out'}
                                        /> */}
                                        <Button
                                            navigation={this.props.navigation}
                                            onPress={context.userSignOut}
                                            buttonStyle={[
                                                Buttons.buttonUnderline,
                                                {
                                                    paddingHorizontal: 0,
                                                    // marginHorizontal:
                                                    //     Layout.paddingMedium,
                                                },
                                            ]}
                                            textColor={Colors.tintColor}
                                            textStyle={{
                                                textDecorationLine: 'underline',
                                            }}
                                            title={'Log out'}
                                        />
                                        <Button
                                            navigation={this.props.navigation}
                                            onPress={() =>
                                                this.props.navigation.navigate(
                                                    'UserAgreement'
                                                )
                                            }
                                            buttonStyle={[
                                                Buttons.buttonUnderline,
                                                {
                                                    marginHorizontal:
                                                        Layout.paddingMedium,
                                                },
                                            ]}
                                            textColor={Colors.tintColor}
                                            textStyle={{
                                                textDecorationLine: 'underline',
                                            }}
                                            title={'Review agreement'}
                                        />
                                    </View>
                                ) : (
                                    <View />
                                )}

                                <View
                                    style={{
                                        flex: 1,
                                        paddingVertical: Layout.isMediumDevice
                                            ? Layout.paddingSmall
                                            : 0,
                                    }}
                                >
                                    <Text
                                        style={[
                                            TextStyles.CaptionInverse,
                                            {
                                                alignSelf: Layout.isMediumDevice
                                                    ? 'center'
                                                    : context.userExists
                                                    ? 'flex-end'
                                                    : 'center',
                                            },
                                        ]}
                                    >
                                        © 2021 Indvstry Inc.
                                    </Text>
                                </View>
                            </View>
                        </View>
                    )
                }}
            </UserProfileConsumer>
        )
    }
}

const styles = StyleSheet.create({
    footer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
        padding: Layout.paddingLarge,
    },
})
