import React from 'react'
import { View, TouchableOpacity, Text } from 'react-native'
import { DrawerActions } from 'react-navigation-drawer'
import { Layout, Buttons, Colors, TextStyles } from '../constants'
import Button from './Button'

export default class DrawerNavigationTabs extends React.Component {
    render() {
        return (
            <View style={Layout.drawerContainer}>
                <View
                    style={{
                        flex: 1,
                        paddingHorizontal: Layout.paddingLarge,
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <TouchableOpacity
                        onPress={() =>
                            this.props.navigation.navigate(
                                this.props.userExists
                                    ? 'PrivateHome'
                                    : 'AuthHome'
                            ) &&
                            this.props.navigation.dispatch(
                                DrawerActions.toggleDrawer()
                            )
                        }
                    >
                        <Text
                            style={[
                                TextStyles.Heading2Inverse,
                                {
                                    paddingBottom: Layout.paddingLarge,
                                },
                            ]}
                        >
                            Home
                        </Text>
                    </TouchableOpacity>

                    {/* {this.props.userExists ? (
                        <TouchableOpacity
                            onPress={() => {
                                this.props.navigation.navigate('MetaProject') &&
                                    this.props.navigation.dispatch(
                                        DrawerActions.toggleDrawer()
                                    )
                            }}
                        >
                            <Text
                                style={[
                                    TextStyles.Heading2,
                                    {
                                        paddingBottom: Layout.paddingLarge,
                                    },
                                ]}
                            >
                                Our vision
                            </Text>
                        </TouchableOpacity>
                    ) : (
                        <TouchableOpacity
                            onPress={() => {
                                this.props.navigation.navigate(
                                    'AuthAgreement'
                                ) &&
                                    this.props.navigation.dispatch(
                                        DrawerActions.toggleDrawer()
                                    )
                            }}
                        >
                            <Text
                                style={[
                                    TextStyles.Heading2,
                                    {
                                        paddingBottom: Layout.paddingLarge,
                                    },
                                ]}
                            >
                                Log in
                            </Text>
                        </TouchableOpacity>
                    )} */}

                    <TouchableOpacity
                        onPress={() => {
                            this.props.navigation.navigate('RequestInfo') &&
                                this.props.navigation.dispatch(
                                    DrawerActions.toggleDrawer()
                                )
                        }}
                    >
                        <Text
                            style={[
                                TextStyles.Heading2Inverse,
                                {
                                    paddingBottom: Layout.paddingLarge,
                                },
                            ]}
                        >
                            Follow
                        </Text>
                    </TouchableOpacity>
                </View>
                {this.props.userExists ? (
                    <View
                        style={{
                            flexShrink: 1,
                            borderTopColor: Colors.lightBackground,
                            borderTopWidth: 1,
                            padding: Layout.paddingLarge,
                            alignItems: 'flex-start',
                        }}
                    >
                        <Button
                            navigation={this.props.navigation}
                            onPress={this.props.onPress}
                            buttonStyle={[
                                Buttons.buttonUnderline,
                                { paddingHorizontal: 0 },
                            ]}
                            textColor={Colors.tintColor}
                            textStyle={{
                                textDecorationLine: 'underline',
                            }}
                            title={'Log out'}
                        />

                        <Button
                            navigation={this.props.navigation}
                            onPress={() =>
                                this.props.navigation.navigate(
                                    'UserAgreement'
                                ) &&
                                this.props.navigation.dispatch(
                                    DrawerActions.toggleDrawer()
                                )
                            }
                            buttonStyle={[
                                Buttons.buttonUnderline,
                                { paddingHorizontal: 0 },
                            ]}
                            textColor={Colors.tintColor}
                            textStyle={{
                                textDecorationLine: 'underline',
                            }}
                            title={'Review agreement'}
                        />
                    </View>
                ) : (
                    <View />
                )}
            </View>
        )
    }
}
