import React from 'react'
import {
    ActivityIndicator,
    StyleSheet,
    TouchableOpacity,
    View,
} from 'react-native'
import { Buttons, Colors, Icons } from '../constants'
import { Feather, MaterialCommunityIcons } from '@expo/vector-icons'

export default class ButtonIcon extends React.Component {
    render() {
        let style = [Buttons.buttonIcon]
        if (this.props.disabled) {
            style.push(Buttons.disabledButtonIcon)
        }
        return (
            <TouchableOpacity
                style={[style, this.props.buttonStyle]}
                disabled={this.props.disabled}
                //onPressIn={this.props.onPressIn}
                onPress={this.props.onPress}
                //underlayColor={Colors.highlightColor}
                //underlayColor={Colors.transparentBackground}
            >
                <View
                    style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    {this.props.loading ? (
                        <ActivityIndicator
                            size="small"
                            color={
                                this.props.activityColor
                                    ? this.props.activityColor
                                    : Colors.inverseIcon
                            }
                        />
                    ) : this.props.materialIcon ? (
                        <MaterialCommunityIcons
                            name={this.props.iconName}
                            size={
                                this.props.iconSize
                                    ? this.props.iconSize
                                    : Icons.medium
                            }
                            color={
                                this.props.disabled
                                    ? Colors.disabled
                                    : this.props.iconColor
                                    ? this.props.iconColor
                                    : Colors.inverseIcon
                            }
                        />
                    ) : (
                        <Feather
                            name={this.props.iconName}
                            size={
                                this.props.iconSize
                                    ? this.props.iconSize
                                    : Icons.medium
                            }
                            color={
                                this.props.disabled
                                    ? Colors.disabled
                                    : this.props.iconColor
                                    ? this.props.iconColor
                                    : Colors.inverseIcon
                            }
                        />
                    )}
                </View>
            </TouchableOpacity>
        )
    }
}

const styles = StyleSheet.create({
    button: {
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 3,
        paddingVertical: 16,
        paddingHorizontal: 16,
        backgroundColor: Colors.tintColor,
        borderWidth: 1,
        borderColor: Colors.tintColor,
    },
    disabledButton: {
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 3,
        paddingVertical: 16,
        paddingHorizontal: 16,
        backgroundColor: 'transparent',
        borderWidth: 1,
        borderColor: Colors.disabled,
    },
})
