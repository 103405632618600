import React from 'react'
import { ScrollView, Text, TextInput, View } from 'react-native'
import { Layout, Colors, TextStyles, Icons } from '../../constants'
import { UserProfileConsumer } from '../../context/UserProfileContext'
import { WindowResizeConsumer } from '../../context/WindowResizeContext'
import Button from '../../components/Button'
import { Feather } from '@expo/vector-icons'

export default class RequestInfo extends React.Component {
    state = {
        emailFocused: false,
    }

    renderInputEmail = ({ emailNew, emailNewValid }) => {
        return (
            <View
                style={{
                    flex: 1,
                    paddingBottom: Layout.paddingMedium,
                }}
            >
                <Text
                    numberOfLines={1}
                    style={[
                        TextStyles.Overline,
                        {
                            flex: 1,
                            paddingBottom: 16,
                            textTransform: 'uppercase',
                        },
                    ]}
                >
                    Email
                </Text>

                <View
                    style={[
                        {
                            flex: 1,
                            padding: Layout.paddingMedium,
                            borderRadius: Layout.borderRadiusXL,
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'row',
                            backgroundColor: '#EEEEEE',
                        },
                    ]}
                >
                    <Feather
                        size={Icons.small}
                        name={'mail'}
                        color={'rgba(0,0,0,0.3)'}
                    />
                    <TextInput
                        //keyboardAppearance={'dark'}
                        style={[
                            TextStyles.Paragraph,
                            {
                                flex: 1,
                                color: Colors.primaryText,
                                outlineWidth: 0,
                                outline: 'none',
                                paddingHorizontal: Layout.paddingSmall,
                            },
                        ]}
                        value={emailNew}
                        placeholder="you@example.com"
                        placeholderTextColor={'rgba(0,0,0,0.4)'}
                        selectionColor={Colors.accentGreen}
                        onChangeText={async (text) => {
                            await this.context.setEmailNew(text)
                            this.context.validateEmailNew(text)
                        }}
                        onFocus={() => {
                            this.setState({
                                fullNameFocused: false,
                                emailFocused: true,
                            })
                        }}
                        onEndEditing={(text) => {
                            this.context.validateEmailNew(text)
                        }}
                        maxLength={64}
                        keyboardType="email-address"
                        autoCapitalize="none"
                        returnKeyType="next"
                        blurOnSubmit={true}
                        autoCorrect={false}
                    />

                    <Feather
                        size={Icons.small}
                        name={'check'}
                        color={
                            !emailNew.length || !emailNewValid
                                ? 'transparent'
                                : Colors.accentGreen
                        }
                    />
                </View>
                <Text
                    numberOfLines={1}
                    style={[
                        TextStyles.Caption,
                        {
                            flex: 1,
                            paddingVertical: 16,
                            color:
                                emailNew.length && !emailNewValid
                                    ? Colors.accentRed
                                    : 'rgba(0,0,0,0.4)',
                        },
                    ]}
                >
                    Email must be formatted correctly
                </Text>
            </View>
        )
    }

    render() {
        return (
            <WindowResizeConsumer>
                {(windowContext) => (
                    <View
                        style={{ flex: 1 }}
                        ref={(ref) => {
                            this.windowContext = windowContext
                        }}
                    >
                        <UserProfileConsumer>
                            {(context) => {
                                return (
                                    <View
                                        style={{
                                            flex: 1,
                                        }}
                                        ref={(ref) => {
                                            this.context = context
                                        }}
                                    >
                                        <ScrollView
                                            style={{
                                                flex: 1,
                                            }}
                                        >
                                            <View
                                                style={Layout.sectionContainer}
                                            >
                                                <View
                                                    style={{
                                                        flex: 1,
                                                        paddingTop: Layout.isMediumDevice
                                                            ? 96
                                                            : 0,
                                                        justifyContent: Layout.isMediumDevice
                                                            ? 'flex-start'
                                                            : 'center',
                                                        paddingHorizontal:
                                                            Layout.paddingMedium,
                                                    }}
                                                >
                                                    <View
                                                        style={[
                                                            {
                                                                maxWidth: 1440,
                                                            },
                                                        ]}
                                                    >
                                                        <Text
                                                            style={[
                                                                TextStyles.Paragraph,
                                                                {
                                                                    paddingBottom:
                                                                        Layout.paddingLarge,
                                                                },
                                                            ]}
                                                        >
                                                            Submit your email to
                                                            get updates about
                                                            early access.
                                                        </Text>

                                                        {this.renderInputEmail({
                                                            emailNew:
                                                                context.emailNew,
                                                            emailNewValid:
                                                                context.emailNewValid,
                                                        })}

                                                        <Button
                                                            buttonStyle={[
                                                                {
                                                                    alignSelf:
                                                                        'center',
                                                                    minWidth: 120,
                                                                    borderRadius:
                                                                        Layout.borderRadiusXL,
                                                                },
                                                            ]}
                                                            title={'Sign up'}
                                                            disabled={
                                                                !context.emailNewValid
                                                            }
                                                            onPress={() => {
                                                                this.context.onSubmitRequestForm()
                                                                this.props.navigation.navigate(
                                                                    'RequestConfirmation'
                                                                )
                                                            }}
                                                        />
                                                    </View>
                                                </View>
                                            </View>
                                        </ScrollView>
                                    </View>
                                )
                            }}
                        </UserProfileConsumer>
                    </View>
                )}
            </WindowResizeConsumer>
        )
    }
}
