import React from 'react'
import { ScrollView, View, Text } from 'react-native'
import { Linking } from 'expo'
import { Colors, Buttons, TextStyles, Layout } from '../../constants'
import Button from '../../components/Button'
import Footer from '../../components/Footer'

export default class Contact extends React.Component {
    render() {
        return (
            <View
                style={{
                    flex: 1,
                    backgroundColor: Colors.gray0,
                }}
            >
                <View
                    style={[
                        Layout.bannerHeaderContainer,
                        {
                            backgroundColor: Colors.gray0,
                            zIndex: -100,
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                        },
                    ]}
                >
                    <View style={Layout.columnOne}>
                        <Text style={TextStyles.Heading0}>Contact</Text>
                    </View>
                </View>
                <ScrollView style={{ flex: 1 }}>
                    <View
                        style={[
                            Layout.bannerHeaderContainer,
                            {
                                backgroundColor: 'transparent',
                            },
                        ]}
                    />
                    <View style={Layout.sectionFooterContainer}>
                        <View style={Layout.columnOne}>
                            <Button
                                iconLeft={true}
                                iconName={'mail'}
                                onPress={() =>
                                    // Linking.openURL(
                                    //     'mailto: hello@mauricekenji.com'
                                    // )
                                    Linking.openURL('mailto: tbd@email.com')
                                }
                                //textColor={Colors.tintColor}
                                buttonStyle={[
                                    //Buttons.buttonOutline,

                                    {
                                        alignSelf: 'center',
                                    },
                                ]}
                                title={'tbd@email.com'}
                            />

                            <View
                                style={{
                                    flexDirection: Layout.isMediumDevice
                                        ? 'column'
                                        : 'row',
                                    alignItems: 'center',
                                    paddingTop: Layout.isMediumDevice
                                        ? Layout.paddingMedium
                                        : Layout.paddingLarge,
                                }}
                            >
                                <Button
                                    navigation={this.props.navigation}
                                    onPress={() =>
                                        Linking.openURL('https://twitter.com/')
                                    }
                                    buttonStyle={[
                                        Buttons.buttonUnderline,
                                        {
                                            marginHorizontal:
                                                Layout.paddingSmall,
                                        },
                                    ]}
                                    textColor={Colors.tintColor}
                                    textStyle={{
                                        textDecorationLine: 'underline',
                                    }}
                                    title={'Twitter'}
                                />

                                <Button
                                    navigation={this.props.navigation}
                                    onPress={() =>
                                        Linking.openURL(
                                            'https://instagram.com/'
                                        )
                                    }
                                    buttonStyle={[
                                        Buttons.buttonUnderline,
                                        {
                                            marginHorizontal:
                                                Layout.paddingSmall,
                                        },
                                    ]}
                                    textColor={Colors.tintColor}
                                    textStyle={{
                                        textDecorationLine: 'underline',
                                    }}
                                    title={'Instagram'}
                                />
                            </View>
                        </View>
                        <Footer navigation={this.props.navigation} />
                    </View>
                </ScrollView>
            </View>
        )
    }
}
