import React from 'react'
import { ScrollView, TouchableOpacity, Text, View } from 'react-native'
import { Layout, Buttons, Colors, TextStyles } from '../../constants'
import Button from '../../components/Button'

export default class AuthAgreementScreen extends React.Component {
    render() {
        return (
            <ScrollView style={{ flex: 1 }}>
                <View
                    style={[
                        Layout.sectionContainer,
                        { backgroundColor: Colors.gray0 },
                    ]}
                >
                    <View
                        style={[
                            Layout.columnOne,
                            {
                                maxWidth: 720,
                                paddingVertical: Layout.topNavHeightDesktop,
                            },
                        ]}
                    >
                        <View
                            style={[
                                Layout.cardContent,
                                {
                                    paddingBottom: Layout.paddingLarge,
                                },
                            ]}
                        >
                            <Text
                                style={[
                                    TextStyles.Heading3,
                                    {
                                        paddingBottom: Layout.paddingMedium,
                                    },
                                ]}
                            >
                                Thank you for your interest in Collective.
                            </Text>
                            <Text
                                style={[
                                    TextStyles.Paragraph,
                                    {
                                        paddingBottom: Layout.paddingLarge,
                                    },
                                ]}
                            >
                                {/* This is an agreement you’re making in order to
                                view our content.  */}
                                By choosing “I accept,” you agree to respect the
                                terms of our agreement.
                            </Text>
                            <View
                                style={{
                                    // paddingTop:
                                    //     Layout.paddingLarge,
                                    flexDirection: Layout.isMediumDevice
                                        ? 'column-reverse'
                                        : 'row',
                                    alignSelf: Layout.isMediumDevice
                                        ? 'center'
                                        : 'flex-end',
                                }}
                            >
                                <Button
                                    onPress={() => {
                                        this.props.navigation.goBack()
                                    }}
                                    buttonStyle={[
                                        Buttons.buttonUnderline,
                                        {
                                            marginVertical: Layout.isMediumDevice
                                                ? Layout.paddingMedium
                                                : null,
                                            marginHorizontal: Layout.isMediumDevice
                                                ? null
                                                : Layout.paddingLarge,
                                        },
                                    ]}
                                    textColor={Colors.tintColor}
                                    textStyle={{
                                        textDecorationLine: 'underline',
                                    }}
                                    title={'Decline'}
                                />
                                <Button
                                    iconLeft={true}
                                    iconName={'check'}
                                    onPress={() => {
                                        this.props.navigation.navigate('LogIn')
                                    }}
                                    title={'I accept'}
                                />
                            </View>
                        </View>
                        <View
                            style={{
                                paddingTop: Layout.paddingLarge,
                                alignSelf: 'center',
                            }}
                        >
                            <Button
                                onPress={() => {
                                    this.props.navigation.navigate(
                                        'UserAgreement'
                                    )
                                }}
                                title={'View full agreement'}
                                buttonStyle={[
                                    Buttons.buttonMini,
                                    {
                                        alignSelf: Layout.isMediumDevice
                                            ? 'center'
                                            : 'flex-start',
                                    },
                                ]}
                                textColor={Colors.tintColor}
                            />
                        </View>
                    </View>
                    {/* <View style={Layout.basicContainer}>
                        <Text style={TextStyles.Paragraph}>
                            Accept agreement and log in to view selected work,
                            or{' '}
                            <TouchableOpacity
                                onPress={() =>
                                    this.props.navigation.navigate(
                                        'RequestInfo'
                                    )
                                }
                            >
                                <Text
                                    style={[
                                        TextStyles.Paragraph,
                                        {
                                            textDecorationLine: 'underline',
                                        },
                                    ]}
                                >
                                    request access
                                </Text>
                            </TouchableOpacity>
                            .
                        </Text>
                    </View> */}
                </View>
            </ScrollView>
        )
    }
}
