import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
//import 'firebase/analytics'

import secrets from './firebase.secrets'

/**
 *  Status: does not work in this workflow configuration.
 *
 *  SDK Auto-configuration
 *  https://firebase.google.com/docs/hosting/reserved-urls#sdk_auto-configuration
 *
 **/

// const config = fetch('/__/firebase/init.json').then(async response => {
//     firebase.initializeApp(await response.json())
// })

const config = {
    apiKey: secrets.apiKey,
    authDomain: secrets.authDomain,
    databaseURL: secrets.databaseURL,
    projectId: secrets.projectId,
    storageBucket: secrets.storageBucket,
}

if (!firebase.apps.length) {
    firebase.initializeApp(config).firestore()
    //firebase.analytics()
}
export default config
