import React from 'react'
import {
    Image,
    ImageBackground,
    ScrollView,
    TouchableOpacity,
    Text,
    View,
} from 'react-native'
import { Linking } from 'expo'
import { BlurView } from 'expo-blur'
import { LinearGradient } from 'expo-linear-gradient'
import { Layout, Buttons, Colors, TextStyles } from '../../constants'
import { UserProfileConsumer } from '../../context/UserProfileContext'
import { WindowResizeConsumer } from '../../context/WindowResizeContext'
import Button from '../../components/Button'
import Footer from '../../components/Footer'

export default class AuthHomeScreen extends React.Component {
    componentDidMount = () => {
        //this.props.navigation.setParams({ active: false })

        this.context.initializeUserAccount()

        /* For special functions only */
        //this.context.uploadVideoToFirestore()
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.isFocused !== this.props.isFocused) {
            this.context.initializeUserAccount()
        }
    }

    onScroll = async (event) => {
        const pageYOffset = await event.nativeEvent.contentOffset.y

        if (pageYOffset >= Layout.SCREEN_HEIGHT - 72) {
            this.props.navigation.setParams({ active: true })
        } else {
            this.props.navigation.setParams({ active: false })
        }
    }

    renderSeparator() {
        return (
            <View
                style={{
                    //flex: 1,
                    borderBottomWidth: 1,
                    borderBottomColor: Colors.border,
                }}
            />
        )
    }

    renderColumnSeparator = () => {
        return (
            <View
                style={{
                    backgroundColor: Colors.white,
                    width: Layout.isMediumDevice ? '100%' : 1,
                    height: Layout.isMediumDevice ? 1 : null,
                }}
            />
        )
    }

    render() {
        return (
            <WindowResizeConsumer>
                {(windowContext) => (
                    <View
                        style={{ flex: 1 }}
                        ref={(ref) => {
                            this.windowContext = windowContext
                        }}
                    >
                        <UserProfileConsumer>
                            {(context) => {
                                return (
                                    <View
                                        style={{
                                            flex: 1,
                                        }}
                                        ref={(ref) => {
                                            this.context = context
                                        }}
                                    >
                                        {/* <LinearGradient
                                            start={[0.0, 0.0]}
                                            end={[1.0, 1.0]}
                                            colors={[
                                                Colors.coral,
                                                Colors.deepcoral,
                                            ]}
                                            style={[
                                                Layout.sectionContainer,
                                                {
                                                    backgroundColor:
                                                        Colors.gray0,
                                                    zIndex: -100,
                                                    position: 'absolute',
                                                    top: 0,
                                                    left: 0,
                                                    right: 0,
                                                    bottom: 0,
                                                },
                                            ]}
                                        /> */}
                                        {/* <ImageBackground
                                            source={require('../../assets/images/maarten-deckers-YDNvydD1jAY-unsplash.jpg')}
                                            resizeMode={'cover'}
                                            style={[
                                                Layout.sectionContainer,
                                                {
                                                    backgroundColor:
                                                        Colors.gray0,
                                                    zIndex: -100,
                                                    position: 'absolute',
                                                    top: 0,
                                                    left: 0,
                                                    right: 0,
                                                    bottom: 0,
                                                },
                                            ]}
                                        >
                                            <View
                                                style={
                                                    Layout.contentOverlayHero
                                                }
                                            />
                                        </ImageBackground> */}
                                        {/* <View
                                            style={[
                                                Layout.sectionContainer,
                                                {
                                                    backgroundColor:
                                                        Colors.gray0,
                                                    zIndex: -100,
                                                    position: 'absolute',
                                                    top: 0,
                                                    left: 0,
                                                    right: 0,
                                                    bottom: 0,
                                                },
                                            ]}
                                        >
                                            <Image
                                                source={require('../../assets/images/cedric-tyaglovsky-U2Ug1A6tQew-unsplash.jpg')}
                                                resizeMode={'cover'}
                                                style={Layout.homeHeroImage}
                                            />
                                        </View> */}
                                        <ScrollView
                                            style={{
                                                flex: 1,
                                            }}
                                            onScroll={(event) =>
                                                this.onScroll(event)
                                            }
                                            onScroll={(event) =>
                                                Layout.isTabletDevice
                                                    ? this.onScroll(event)
                                                    : null
                                            }
                                            scrollEventThrottle={16}
                                        >
                                            <View
                                                style={Layout.sectionContainer}
                                            >
                                                <ImageBackground
                                                    source={require('../../assets/images/gradienta-ix_kUDzCczo-unsplash.jpg')}
                                                    resizeMode={'cover'}
                                                    style={{
                                                        position: 'absolute',

                                                        height: Layout.isMediumDevice
                                                            ? Layout.SCREEN_WIDTH *
                                                              1.236
                                                            : Layout.SCREEN_WIDTH *
                                                              0.5,
                                                        width: Layout.isMediumDevice
                                                            ? Layout.SCREEN_WIDTH *
                                                              1.236
                                                            : Layout.SCREEN_WIDTH *
                                                              0.5,
                                                        borderRadius: 1000,
                                                        overflow: 'hidden',
                                                        alignItems: 'center',
                                                        justifyContent:
                                                            'center',
                                                    }}
                                                >
                                                    <BlurView
                                                        intensity={100}
                                                        tint={'default'}
                                                        style={[
                                                            {
                                                                alignSelf:
                                                                    'center',
                                                                height: Layout.isMediumDevice
                                                                    ? Layout.SCREEN_WIDTH *
                                                                      1.236
                                                                    : Layout.SCREEN_WIDTH *
                                                                      0.5,
                                                                width: Layout.isMediumDevice
                                                                    ? Layout.SCREEN_WIDTH +
                                                                      1.236
                                                                    : Layout.SCREEN_WIDTH *
                                                                      0.5,
                                                            },
                                                        ]}
                                                    />
                                                </ImageBackground>

                                                <View
                                                    style={{
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        justifyContent:
                                                            'center',
                                                        padding: Layout.isMediumDevice
                                                            ? Layout.paddingLarge
                                                            : Layout.paddingXL,
                                                    }}
                                                >
                                                    <Text
                                                        style={[
                                                            TextStyles.HeadingHero,
                                                            {
                                                                textAlign:
                                                                    'center',
                                                                color:
                                                                    Colors.tintColor,
                                                            },
                                                        ]}
                                                    >
                                                        Collaborative
                                                        {'\n'}
                                                        group stories
                                                    </Text>
                                                </View>
                                                <Button
                                                    onPress={() =>
                                                        this.props.navigation.navigate(
                                                            'RequestInfo'
                                                        )
                                                    }
                                                    buttonStyle={[
                                                        Buttons.button,
                                                        {
                                                            alignSelf: 'center',
                                                            borderRadius:
                                                                Layout.borderRadiusXL,
                                                        },
                                                    ]}
                                                    title={'Join early access'}
                                                />
                                            </View>

                                            <View
                                                style={[
                                                    {
                                                        flex: 1,
                                                        backgroundColor:
                                                            Colors.tintColor,
                                                        paddingHorizontal:
                                                            Layout.paddingLarge,
                                                        paddingTop:
                                                            Layout.paddingXXL,
                                                        paddingBottom:
                                                            Layout.paddingXL,
                                                        alignItems: 'center',
                                                        justifyContent:
                                                            'center',
                                                    },
                                                ]}
                                            >
                                                <View
                                                    style={[
                                                        {
                                                            flex: 1,
                                                            alignSelf: 'center',
                                                            alignItems:
                                                                'center',
                                                            justifyContent:
                                                                'center',
                                                            maxWidth: Layout.isTabletDevice
                                                                ? 480
                                                                : 720,

                                                            width: '100%',
                                                        },
                                                    ]}
                                                >
                                                    <Text
                                                        style={[
                                                            Layout.isMediumDevice
                                                                ? TextStyles.Heading3Inverse
                                                                : TextStyles.Heading2Inverse,
                                                            {
                                                                textAlign:
                                                                    'center',
                                                                paddingBottom:
                                                                    Layout.paddingLarge,
                                                                lineHeight: Layout.isMediumDevice
                                                                    ? 36
                                                                    : 54,
                                                            },
                                                        ]}
                                                    >
                                                        Collective is made with
                                                        your privacy and safety
                                                        in mind. We support
                                                        private groups with
                                                        content controls and
                                                        managed history. In
                                                        addition, encryption and
                                                        secure data storage is
                                                        built in.
                                                    </Text>
                                                    <Button
                                                        onPress={() =>
                                                            this.props.navigation.navigate(
                                                                'RequestInfo'
                                                            )
                                                        }
                                                        textColor={Colors.white}
                                                        buttonStyle={[
                                                            Buttons.buttonOutlineInverse,
                                                            {
                                                                alignSelf:
                                                                    'center',
                                                                borderRadius:
                                                                    Layout.borderRadiusXL,
                                                            },
                                                        ]}
                                                        title={'Get updates'}
                                                    />
                                                </View>
                                            </View>
                                            <Footer
                                                navigation={
                                                    this.props.navigation
                                                }
                                            />
                                        </ScrollView>
                                    </View>
                                )
                            }}
                        </UserProfileConsumer>
                    </View>
                )}
            </WindowResizeConsumer>
        )
    }
}
