import React from 'react'
import { Text, View } from 'react-native'
import { TextStyles } from '../constants'

export default class TopNavHeaderTitle extends React.Component {
    render = () => {
        return (
            <View
                style={[
                    this.props.headerStyle,
                    {
                        flex: 1,
                        alignItems: 'center',
                    },
                ]}
            >
                {/* <Text
                    style={[TextStyles.Heading3, this.props.headerTitleStyle]}
                >
                    {this.props.headerTitle}
                </Text> */}
                <Text
                    style={[TextStyles.Logotype, this.props.headerTitleStyle]}
                >
                    {this.props.headerTitle}
                </Text>
            </View>
        )
    }
}
