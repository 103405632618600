const tintColor = '#111111'
const black = '#000000'
const white = '#ffffff'

// Original grays
// const gray0 = '#f9f9f9'
// const gray1 = '#ebebeb'
// const gray2 = '#d5d5d5'
// const gray3 = '#bcbcbc'
// const gray4 = '#9d9d9d'
// const gray5 = '#747474'

// Aesop warm 'grays'
// https://palx.jxnblk.com/fffef2
// https://htmlcolorcodes.com/color-picker/

const gray0 = '#f9f9f9'
const gray1 = '#ededed'
const gray2 = '#e1e1e1'
const gray3 = '#c4c4c4'
const gray4 = '#a0a0a0'
const gray5 = '#6c6c6c'

const shadowColor = '#000000'
const shadowOffset = { width: 0, height: 1 }
const shadowOpacity = 0.2
const shadowRadius = 2

const shadowOffsetDepth = { width: 0, height: 4 }
const shadowOpacityDepth = 0.1
const shadowRadiusDepth = 6

export default {
    // Base Colors
    tintColor,
    black,
    white,
    gray0,
    gray1,
    gray2,
    gray3,
    gray4,
    gray5,

    // BGs
    primaryBackground: white,
    secondaryBackground: gray0,
    lightBackground: 'rgba(0,0,0,0.17)',
    mediumBackground: 'rgba(0,0,0,0.34)',
    darkBackground: 'rgba(0,0,0,0.72)',
    transparentBackground: 'rgba(0,0,0,0)',
    tabBarLightBackground: 'rgba(255,254,240, .5)', // <-- Gray 0 with transparency

    // Color palette
    matte: 'rgba(72,64,64,1)',
    deepmatte: 'rgba(36,32,32,1)',

    bern: '#DDEDE3',
    aesop: '#f6f5e8',
    sunshine: '#fbe099',
    lightgold: '#F6CC60',
    gold: '#f1ae00',
    coral: '#fa7268',
    deepcoral: '#FF5252',
    lightsea: '#79deb9',
    marine: '#26477c',
    deepsea: '#004150' /* currently selected */,

    // Accent colors
    accentRed: '#ff1744',
    accentYellow: '#ffea00',
    accentBlue: '#2962ff',
    accentMarine: '#12098f',
    accentGreen: '#00e676',
    accentSea: '#1de9b6',
    accentDeepsea: '#00bfa5' /* currently selected */,

    accentTint1: '#12098f',
    accentTint2: '#30289d',
    accentTint3: '#4d47ab',
    accentTint4: '#6b65b9',
    accentTint5: '#8984c7',
    accentTint6: '#a6a3d5',
    accentTint7: '#c4c2e3',
    accentTint8: '#e1e0f1',

    // States and Elements
    tabIconSelected: tintColor,
    tabIconSelectedInverse: gray0,
    tabBar: gray0,
    tabBarBlack: black,
    border: gray2, // was gray1
    disabled: gray2,
    disabledAlt: 'rgba(231,230,219, 0.32)',

    tabIconDefault: gray2,
    highlightColor: gray5,

    // Text colors
    primaryText: tintColor,
    secondaryText: gray5,
    inverseText: white, // was gray0, was white,
    inverseTextDisabled: 'rgba(255,255,255,0.48)',

    // Icon colors
    primaryIcon: tintColor,
    secondaryIcon: gray4,
    inverseIcon: white, //gray0,
    inverseIconDisabled: 'rgba(255,255,255,0.24)',

    shadow: {
        shadowColor: shadowColor,
        shadowOffset: shadowOffset,
        shadowOpacity: shadowOpacity,
        shadowRadius: shadowRadius,
    },
    shadowDepth: {
        shadowColor: shadowColor,
        shadowOffset: shadowOffsetDepth,
        shadowOpacity: shadowOpacityDepth,
        shadowRadius: shadowRadiusDepth,
    },

    // Shadows
    shadowColor,
    shadowOffset,
    shadowOpacity,
    shadowRadius,

    shadowOffsetDepth,
    shadowOpacityDepth,
    shadowRadiusDepth,
}
