import React from 'react'
import { View } from 'react-native'
import Slider from 'react-native-simple-slider'
import { Colors, Layout } from '../constants'

export default class FormSlider extends React.Component {
    render = () => {
        return (
            <View
                style={[
                    this.props.headerStyle,
                    {
                        flex: 1,
                        alignItems: 'center',
                    },
                ]}
            >
                <Slider
                    sliderWidth={Layout.SCREEN_WIDTH * 0.236}
                    thumbButtonSize={0}
                    sliderHeight={4}
                    sliderBorderRadius={8}
                    minimumTrackTintColor={Colors.tintColor}
                    maximumTrackTintColor={Colors.gray2}
                    thumbTintColor={Colors.tintColor}
                    value={this.props.sliderValue}
                    maximumValue={this.props.sliderMax}
                    disabled={!this.props.isLoaded}
                />
            </View>
        )
    }
}
