import Layout from './Layout'
import Colors from './Colors'

const light = 'inter-light'
const regular = 'inter-regular'
const medium = 'inter-medium'
const semibold = 'inter-semibold'
const bold = 'inter-bold'

const HeadingHeroLineHeight = Layout.isMediumDevice
    ? Layout.SCREEN_WIDTH * 0.125
    : Layout.SCREEN_WIDTH * 0.1
const HeadingHeroFontSize = Layout.isMediumDevice
    ? Layout.SCREEN_WIDTH * 0.125
    : Layout.SCREEN_WIDTH * 0.1
const HeadingHeroLetterSpacing = Layout.isMediumDevice ? -1 : -4

const LogotypeFontSize = Layout.isMediumDevice ? 21 : 24

const Heading0LineHeight = Layout.isMediumDevice ? 56 : 92
const Heading0FontSize = Layout.isMediumDevice ? 44 : 88

const Heading1LineHeight = Layout.isMediumDevice ? 40 : 48
const Heading1FontSize = Layout.isMediumDevice ? 34 : 42

const Heading2LineHeight = Layout.isMediumDevice ? 40 : 48
const Heading2FontSize = Layout.isMediumDevice ? 28 : 32

const Heading3LineHeight = Layout.isMediumDevice ? 28 : 32
const Heading3FontSize = 24

const SubtitleLineHeight = 40
const SubtitleFontSize = 21

const ParagraphLineHeight = 32
const ListParagraphLineHeight = 22
const ParagraphFontSize = 18

const CaptionLineHeight = 28
const CaptionFontSize = 15

const OverlineFontSize = 14

const ButtonFontSize = 16

export default {
    ListParagraphLineHeight,

    HeadingHero: {
        fontFamily: Layout.isMediumDevice ? 'inter-medium' : 'inter-light',
        fontSize: HeadingHeroFontSize,
        color: Colors.primaryText,
        lineHeight: HeadingHeroLineHeight,
        letterSpacing: HeadingHeroLetterSpacing,
    },

    Logotype: {
        fontFamily: 'inter-black',
        fontSize: LogotypeFontSize,
        color: Colors.primaryText,
        letterSpacing: -0.5,
    },
    Heading0: {
        fontFamily: bold,
        fontSize: Heading0FontSize,
        letterSpacing: -1.5,
        color: Colors.primaryText,
        lineHeight: Heading0LineHeight,
        //textTransform: 'uppercase',
    },
    Heading1: {
        fontFamily: semibold,
        fontSize: Heading1FontSize,
        letterSpacing: -1,
        color: Colors.primaryText,
        lineHeight: Heading1LineHeight,
        //textTransform: 'uppercase',
    },
    Heading2: {
        fontFamily: regular,
        fontSize: Heading2FontSize,
        letterSpacing: -0.5,
        color: Colors.primaryText,
        lineHeight: Heading2LineHeight,
    },
    Heading3: {
        fontFamily: semibold,
        fontSize: Heading3FontSize,
        color: Colors.primaryText,
        letterSpacing: -0.25,
        lineHeight: Heading3LineHeight,
    },

    Subtitle: {
        fontFamily: semibold,
        fontSize: SubtitleFontSize,
        color: Colors.primaryText,
        lineHeight: SubtitleLineHeight,
    },
    Paragraph: {
        fontFamily: regular,
        fontSize: ParagraphFontSize,
        color: Colors.primaryText,
        lineHeight: ParagraphLineHeight,
    },

    Caption: {
        fontFamily: regular,
        fontSize: CaptionFontSize,
        color: Colors.secondaryText,
        letterSpacing: 0.5,
        lineHeight: CaptionLineHeight,
    },

    Overline: {
        fontFamily: bold,
        fontSize: OverlineFontSize,
        color: Colors.primaryText,
        letterSpacing: 2.5,
        textTransform: 'uppercase',
        lineHeight: CaptionLineHeight,
    },

    Button: {
        fontFamily: medium,
        fontSize: ButtonFontSize,
        letterSpacing: 0.25,
        color: Colors.primaryText,
    },

    // Inverse text with shadow
    LogotypeInverse: {
        fontFamily: semibold,
        fontSize: LogotypeFontSize,
        color: Colors.inverseText,
        letterSpacing: -0.15,
    },

    Heading0Inverse: {
        fontFamily: bold,
        fontSize: Heading0FontSize,
        letterSpacing: -1.5,
        color: Colors.inverseText,
        lineHeight: Heading0LineHeight,
        textTransform: 'uppercase',
    },

    Heading1Inverse: {
        fontFamily: semibold,
        fontSize: Heading1FontSize,
        letterSpacing: -1,
        color: Colors.inverseText,
        lineHeight: Heading1LineHeight,
        //textTransform: 'uppercase',
    },

    Heading2Inverse: {
        fontFamily: regular,
        fontSize: Heading2FontSize,
        letterSpacing: -0.5,
        color: Colors.inverseText,
        lineHeight: Heading2LineHeight,
    },

    Heading3Inverse: {
        fontFamily: semibold,
        fontSize: Heading3FontSize,
        color: Colors.inverseText,
        letterSpacing: -0.25,
        lineHeight: Heading3LineHeight,
    },

    SubtitleInverse: {
        fontFamily: semibold,
        fontSize: SubtitleFontSize,
        color: Colors.inverseText,
        lineHeight: SubtitleLineHeight,
    },
    ParagraphInverse: {
        fontFamily: regular,
        fontSize: ParagraphFontSize,
        color: Colors.inverseText,
        lineHeight: ParagraphLineHeight,
    },
    CaptionInverse: {
        fontFamily: regular,
        fontSize: CaptionFontSize,
        color: Colors.inverseText,
        letterSpacing: 0.5,
        lineHeight: CaptionLineHeight,
    },
    OverlineInverse: {
        fontFamily: bold,
        fontSize: OverlineFontSize,
        color: Colors.inverseText,
        letterSpacing: 2.5,
        textTransform: 'uppercase',
        lineHeight: CaptionLineHeight,
    },
}
