import React from 'react'
import { Alert } from 'react-native'
import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import '../firebase.config'

const DB = firebase.firestore()
const AUTH = firebase.auth()
const USERS_COLLECTION = 'users'
const REQUEST_FORMS_COLLECTION = 'requestForms'
const ACCESS_TOKENS_COLLECTION = 'accessTokens'

class User extends React.Component {
    // Helpers
    get collection() {
        return firebase.firestore().collection(USERS_COLLECTION)
    }

    get uid() {
        return (firebase.auth().currentUser || {}).uid
    }

    get user() {
        return firebase.auth().currentUser
    }

    get timestamp() {
        return Date.now()
    }

    _checkAuthStateChanged = async () => {
        AUTH.onAuthStateChanged(async (user) => {
            const getOnboardValid = []

            if (!user) {
            } else if (user) {
                //await this._getUserAccount()
                //await this._getUserProfile()

                let userProfileRef = await this.collection.doc(this.uid)

                const userProfile = []

                await userProfileRef.get().then((doc) => {
                    if (doc.exists) {
                        //console.log('USER: GET-USER-PROFILE', '›››', doc.data())

                        let onboardValid = doc.data().onboardValid
                        userProfile.push(onboardValid)
                    }
                })

                getOnboardValid.push(userProfile[0])

                //return { getOnboardValid: userProfile[0] }

                //console.log('USER', 'ONBOARDVALID', userProfile[0])
            }
            //console.log('USER', 'ONBOARDVALID', getOnboardValid[0])
            return getOnboardValid
        })
    }

    _getUserAccount = async () => {
        if (this.user != null) {
            return {
                uid: this.user.uid || '',
                fullName: this.user.displayName || '',
                fullNameCurrent: this.user.displayName || '',
                email: this.user.email || '',
                emailVerified: this.user.emailVerified || false,
                photoUrl: this.user.photoURL || 'https://',
            }
        } else {
            return {
                uid: '',
                fullName: '',
                fullNameCurrent: '',
                email: '',
                emailVerified: false,
                photoUrl: 'https://',
            }
        }
    }

    _getUserProfile = async () => {
        if (this.user != null) {
            let userProfileRef = await this.collection.doc(this.uid)

            const userProfile = []

            await userProfileRef.get().then((doc) => {
                if (doc.exists) {
                    userProfile.push(doc.data())
                }
            })

            return userProfile[0]
        } else {
            return {
                visibility: {},
            }
        }
    }

    _signInWithTokenEmailAndPassword = async (email, password, token) => {
        const tokenQuery = await DB.collection(ACCESS_TOKENS_COLLECTION).doc(
            token
        )

        // const tokenQuery = await DB.collection(
        //     ACCESS_TOKENS_COLLECTION
        // ).where('email', '==', email)

        //const tokenQueryData = await tokenQuery.get()
        //const tokenDocInstance = tokenQueryData
        //const tokenDocInstance = tokenQueryData.docs[0]

        try {
            await tokenQuery.get().then((doc) => {
                if (doc.exists) {
                    const tokenData = doc.data()
                    const tokenRedeemed = tokenData.redeemed
                    const hasValidEmail = tokenData.email === email
                    const hasValidToken = tokenData.accessToken === token
                    const fullName = tokenData.fullName
                    const isInvestor = tokenData.role === 'investor'
                    const isCreator = tokenData.role === 'creator'
                    const isStudio = tokenData.role === 'studio'

                    if (tokenRedeemed) {
                        if (hasValidEmail) {
                            this._signInWithEmailAndPassword(email, password)
                        } else {
                            console.error(
                                'This account is not authorized for access.'
                            )
                            Alert.alert(
                                'This account is not authorized for access.'
                            )
                            window.alert(
                                'This account is not authorized for access.'
                            )
                        }
                    } else if (hasValidEmail && hasValidToken) {
                        try {
                            AUTH.createUserWithEmailAndPassword(
                                email,
                                password
                            ).then(async () => {
                                await AUTH.onAuthStateChanged(async () => {
                                    await this._displayNameUpdate(fullName)

                                    await this._onboardCheckAndCreateUserProfile(
                                        isInvestor,
                                        isCreator,
                                        isStudio
                                    )

                                    await this._tokenRedeemed(token)

                                    await this._sendEmailVerification()

                                    //await this._clearUrlToken()
                                })
                            })

                            //await tokenDocInstance.ref.update({ redeemed: true })
                            //await this._clearUrlToken()
                        } catch (error) {
                            //  console.log('Issues with token sign in', error)
                            this._onSignInError(error)
                        }
                    }
                } else {
                    this._signInWithEmailAndPassword(email, password)
                }
            })
        } catch ({ message }) {
            //  console.log({ message })
        }
    }

    _tokenRedeemed = async (token) => {
        const tokenQuery = await DB.collection(ACCESS_TOKENS_COLLECTION).doc(
            token
        )

        try {
            tokenQuery.update({ redeemed: true })
        } catch (error) {
            //  console.log('Token not redeemed')
        }
    }

    _signInWithEmailAndPassword = async (email, password) => {
        try {
            await AUTH.signInWithEmailAndPassword(email, password)
            //this._clearUrlToken()
        } catch (error) {
            this._onSignInError(error)
        }
    }

    _onSignInError = (error) => {
        var errorCode = error.code
        var errorMessage = error.message
        if (errorCode === 'auth/wrong-password') {
            Alert.alert('Passcode is incorrect.')
            window.alert('Passcode is incorrect.')
        } else if (errorCode == 'auth/user-not-found') {
            Alert.alert('An account with this email does not exist.')
            window.alert('An account with this email does not exist.')
        } else {
            Alert.alert(errorMessage)
            window.alert(errorMessage)
        }
        console.error('A sign in error occurred:', error)
    }

    _signOut = () => AUTH.signOut()

    // _clearUrlToken = () => {
    //     const { origin, pathname } = window.location
    //     window.history.replaceState(
    //         history.state,
    //         window.document.title,
    //         `${origin}${pathname}`
    //     )
    // }

    /**
     *
     * Authenticate with email link: https://firebase.google.com/docs/auth/web/email-link-auth?origin_team=T0559781A
     *
     * Step 0: Whitelist email domains in firebase authentication console.
     * Step 1: Get user's email. If email/domain valid, link is sent to email address.
     * Step 2:
     *
     * */

    _sendSignInLinkToEmail = async (email) => {
        // Production mode
        //const actionCodeSettings = { url: 'https://indvstry.io/' }

        // Development mode
        const actionCodeSettings = {
            // URL you want to redirect back to. The domain (www.example.com) for this
            // URL must be whitelisted in the Firebase Console.
            url: 'http://localhost:19006/',
            // This must be true.
            handleCodeInApp: true,
            // iOS: {
            //     bundleId: 'com.example.ios',
            // },
            // android: {
            //     packageName: 'com.example.android',
            //     installApp: true,
            //     minimumVersion: '12',
            // },
            //dynamicLinkDomain: 'example.page.link',
        }

        AUTH.sendSignInLinkToEmail(email, actionCodeSettings)
            .then(() => {
                // The link was successfully sent. Inform the user.
                //  console.log('A log in link was successfully sent.')
                Alert.alert('A log in link was successfully sent.')
                window.alert('A log in link was successfully sent.')

                // Save the email locally so you don't need to ask the user for it again
                // if they open the link on the same device.
                window.localStorage.setItem('emailForSignIn', email)
            })
            .catch(function (error) {
                // Some error occurred, you can inspect the code: error.code
                //  console.log('_sendSignInLinkToEmail: Error', error)
            })
    }

    // _signInWithEmailLink = () => {
    //     // Confirm the link is a sign-in with email link.
    //     if (AUTH.isSignInWithEmailLink(window.location.href)) {
    //         // Additional state parameters can also be passed via URL.
    //         // This can be used to continue the user's intended action before triggering
    //         // the sign-in operation.
    //         // Get the email if available. This should be available if the user completes
    //         // the flow on the same device where they started it.
    //         const email = window.localStorage.getItem('emailForSignIn')
    //         if (!email) {
    //             // User opened the link on a different device. To prevent session fixation
    //             // attacks, ask the user to provide the associated email again. For example:
    //             email = window.prompt(
    //                 'Please provide your email for confirmation'
    //             )
    //         }
    //         // The client SDK will parse the code from the link for you.
    //         AUTH.signInWithEmailLink(email, window.location.href)
    //             .then(function(result) {
    //                 // Clear email from storage.
    //                 window.localStorage.removeItem('emailForSignIn')
    //                 // You can access the new user via result.user
    //                 // Additional user info profile not available via:
    //                 // result.additionalUserInfo.profile == null
    //                 // You can check if the user is new or existing:
    //                 // result.additionalUserInfo.isNewUser
    //             })
    //             .catch(function(error) {
    //                 // Some error occurred, you can inspect the code: error.code
    //                 // Common errors could be invalid email and invalid or expired OTPs.
    //             })
    //     }
    // }

    _passwordReset = (email) => {
        AUTH.sendPasswordResetEmail(email)
            .then(function () {
                // Password reset email sent.
                Alert.alert(
                    'Password reset email has been sent to ' + email + '.'
                )
                window.alert(
                    'Password reset email has been sent to ' + email + '.'
                )
            })
            .catch(function (error) {
                // Error occurred. Inspect error.code.
                var errorCode = error.code
                var errorMessage = error.message
                if (errorCode == 'auth/user-not-found') {
                    Alert.alert('An account with this email does not exist.')
                    window.alert('An account with this email does not exist.')
                } else {
                    Alert.alert(errorMessage)
                }
                //  console.log(error)
            })
    }

    _passwordUpdate = (password) => AUTH.currentUser.updatePassword(password)

    _emailUpdate = (email) => {
        AUTH.currentUser
            .updateEmail(email)
            .catch(function (error) {
                // Handle Errors here.
                var errorCode = error.code
                var errorMessage = error.message
                if (errorCode == 'auth/email-already-in-use') {
                    Alert.alert('An account with this email already exists.')
                } else {
                    Alert.alert(errorMessage)
                }
                //  console.log(error)
            })
            .then(() => this._sendEmailVerification())
    }

    _sendEmailVerification = () => {
        this.user
            .sendEmailVerification()
            .then(() => {
                Alert.alert(
                    'An email verification link has been sent to your email address.'
                )
            })
            .catch((error) => {
                //  console.log(error.message)
            })
    }

    _displayNameUpdate = (fullName) => {
        try {
            this.user.updateProfile({ displayName: fullName })
        } catch ({ message }) {
            //  console.log(message)
            //alert(message)
        }
    }

    _createNewUser = ({
        fullName,
        email,
        password,
        isInvestor,
        isCreator,
        isStudio,
    }) => {
        AUTH.createUserWithEmailAndPassword(email, password).then(async () => {
            await AUTH.onAuthStateChanged(async () => {
                await this._displayNameUpdate(fullName)

                await this._onboardCheckAndCreateUserProfile(
                    isInvestor,
                    isCreator,
                    isStudio
                )

                await this._sendEmailVerification()
            })
        })
    }

    _createUserWithEmailAndPassword = ({ email, password, fullName }) => {
        AUTH.createUserWithEmailAndPassword(email, password)
            .catch(function (error) {
                // Handle Errors here.
                var errorCode = error.code
                //var errorMessage = error.message
                // if (errorCode == 'auth/weak-password') {
                //     Alert.alert('The password is too weak.')
                // } else
                if (errorCode == 'auth/email-already-in-use') {
                    Alert.alert('An account with this email already exists.')
                }
                // else {
                //     Alert.alert(errorMessage)
                // }
                //  console.log(error)
            })
            .then(async () => {
                await AUTH.onAuthStateChanged(async () => {
                    // User is signed in, name added, UserProfile is created.

                    await this._displayNameUpdate(fullName)

                    await this._onboardCheckAndCreateUserProfile(
                        isInvestor,
                        isCreator,
                        isStudio
                    )

                    // this._sendEmailVerification()
                })
            })
        // .then(async () => {
        //     // Then user is reauthenticated. I think this may be kicking people out on new sign-ups.
        //     await this._reauthenticate(password)
        // })
    }

    // Reauthenticates the current user and returns a promise...
    _reauthenticate = (password) => {
        const user = AUTH.currentUser
        const cred = firebase.auth.EmailAuthProvider.credential(
            this.user.email,
            password
        )
        return user.reauthenticateAndRetrieveDataWithCredential(cred)
    }

    /* CREATE PROFILE FROM ONBOARDING */
    _onboardCreateUserProfile = async (isInvestor, isCreator, isStudio) => {
        const userProfileRef = await this.collection.doc(this.uid)

        userProfileRef.set({
            agreeToTermsWork: false,
            agreeToTermsCommunity: false,
            //inProgress: true,
            onboardValid: false,
            placeId: '',
            receiveMarketingEmail: true,
            roles: {
                admin: false,
                investor: isInvestor,
                creator: isCreator,
                studio: isStudio,
                explorer: true,
            },
            username: '',
            venueCurrent: '',
            venueActive: '',
            visibility: {
                group: true,
                owner: true,
                private: false,
                public: false,
                venueCurrent: true,
            },
        })
    }

    /* CHECK FOR EXISTING PROFILE */
    _onboardCheckAndCreateUserProfile = async (
        isInvestor,
        isCreator,
        isStudio
    ) => {
        const userProfileRef = await this.collection.doc(this.uid)

        try {
            if (!userProfileRef.exists) {
                // console.log(
                //     '›››››››››››››››››› This profile does not exist. Creating a new one. ‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹'
                // )
                this._onboardCreateUserProfile(isInvestor, isCreator, isStudio)

                // console.log(
                //     '›››››››››››››››››› Let us get the process going. ‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹'
                // )
            } else {
                //  console.log(
                //     '›››››››››››››››››› This user profile exists ‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹'
                // )
            }
        } catch ({ message }) {
            //  console.log(message)
            //Alert.alert(message)
        }
    }

    /* CREATE REQUEST */
    original_createRequestEntry = async ({ fullName, email }) => {
        try {
            const requestFormsRef = await DB.collection(
                REQUEST_FORMS_COLLECTION
            )

            requestFormsRef.add({
                fullName,
                email,
            })
        } catch ({ message }) {
            //  console.log(message)
        }
    }

    _createRequestEntry = async ({ email }) => {
        const requestFormsRef = await DB.collection(REQUEST_FORMS_COLLECTION)

        try {
            requestFormsRef
                .add({
                    /* Email sent to recipient. */
                    to: email,
                    message: {
                        subject:
                            'We received your request for early access to Collective!',
                        text:
                            'Hello! Your email, ' +
                            email +
                            ' has been added to our waitlist at Collective, a new app for collaborative group stories. Keep an eye out for an invite to our app from Apple TestFlight. Visit https://collective.video/ to learn more. — Team at Collective',

                        html:
                            'Hello! Your email, ' +
                            email +
                            ' has been added to our waitlist at Collective, a new app for collaborative group stories. Keep an eye out for an invite to our app from Apple TestFlight. Visit https://collective.video/ to learn more. — Team at Collective',
                    },
                })
                .then(() => console.log('Queued email for delivery!'))

            requestFormsRef
                .add({
                    /* My inbox to alert of new requests from the website. */
                    to: 'support@collective.video',
                    message: {
                        subject:
                            'Website Request on Collective: ' +
                            email +
                            ' has requested to join Collective through collective.video request form.',
                        text: 'Send a TestFlight invite to: ' + email,
                        html: 'Send a TestFlight invite to: ' + email,
                    },
                })
                .then(() => console.log('Queued support email for delivery!'))
        } catch ({ message }) {
            //alert(message)
            console.log(message)
        }
    }
}

export default new User()
