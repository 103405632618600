import React from 'react'
import { ScrollView, TouchableOpacity, Text, View } from 'react-native'
import { Layout, Buttons, Colors, TextStyles } from '../../constants'
import { UserProfileConsumer } from '../../context/UserProfileContext'

import UserLogIn from '../../components/UserLogIn'

export default class LogIn extends React.Component {
    render() {
        return (
            <UserProfileConsumer>
                {context => {
                    return (
                        <View
                            style={{ flex: 1 }}
                            ref={ref => {
                                this.context = context
                            }}
                        >
                            <ScrollView
                                style={{
                                    flex: 1,
                                }}
                            >
                                <View
                                    style={[
                                        Layout.sectionContainer,
                                        { backgroundColor: Colors.gray0 },
                                    ]}
                                >
                                    <View
                                        style={[
                                            Layout.columnOne,
                                            {
                                                maxWidth: 720,
                                                paddingVertical:
                                                    Layout.topNavHeightDesktop,
                                            },
                                        ]}
                                    >
                                        <UserLogIn
                                            navigation={this.props.navigation}
                                        />
                                    </View>
                                    {/* <View style={Layout.basicContainer}>
                                        <Text style={TextStyles.Paragraph}>
                                            Accept agreement and log in to view
                                            selected work, or{' '}
                                            <TouchableOpacity
                                                onPress={() =>
                                                    this.props.navigation.navigate(
                                                        'RequestInfo'
                                                    )
                                                }
                                            >
                                                <Text
                                                    style={[
                                                        TextStyles.Paragraph,
                                                        {
                                                            textDecorationLine:
                                                                'underline',
                                                        },
                                                    ]}
                                                >
                                                    request access
                                                </Text>
                                            </TouchableOpacity>
                                            .
                                        </Text>
                                    </View> */}
                                </View>
                            </ScrollView>
                        </View>
                    )
                }}
            </UserProfileConsumer>
        )
    }
}
